html {
  margin: 0;
  padding: 0;
  height: 100vh;
}
/* :root {
  box-sizing: border-box;
} */

body {
  background: rgb(31, 40, 68);
  color: #00bfff;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  border: 1px solid #00bfff;
  overflow: hidden;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  font-family: 'League Spartan', 'Arial', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (orientation: portrait) {
  body {
    border: none;
  }
}
